import React, { useState } from 'react';
import './assets/css/style.css';
import CustomCanvas from './components/CustomCanvas';

const App = () => {
    
    const [preferences, setPreferences] = useState({
        active: {
            tool: 'pencil'
        },
        pencil: {
            width: 5,
            color: '#000000',
            opacity: 1
        },
        highlight: {
            width: 5,
            color: 'rgba(255,255,0,0.5)',
            opacity: 0.5
        },
        eraser: {
            width: 5,
            color: '#ffffff',
            opacity: 1
        }
    });

    const [openOptions, setOpenOptions] = useState('');
    const openCloseOptions = (key) => {
        openOptions === key ? setOpenOptions(null) : setOpenOptions(key);
    };

    const createWidthOptions = (key) => {
        let element = [];
        for (let i = 0; i < 3; i++) 
        {
            element.push(
                <div key={i} className={`width ${active(key, 'width', (2 * i) + 1)}`} onClick={() => updatePreferences(key, 'width', (2 * i) + 1)}>
                    <div className={`options pixel-${i + 1}`}></div>
                </div>
            );
        }
        return element;
    };

    const createColorOptions = (key) => {
        let element = 
            <li>
                <div className="width">
                    <div className="options color-option" style={{ background: preferences.[key].color }}></div>
                </div>
                <div className="width">
                    <img src={`icons/color.svg`} alt=""/>
                    <input 
                        type="color" 
                        onChange={e => updatePreferences(key, 'color', hexToRgbA(key, e.target.value))}
                    />
                </div>
            </li>
        ;
        return element;
    }

    const active = (object, key, value) => {
        return preferences.[object].[key] === value ? 'active' : '';
    };

    const updatePreferences = (object, key, value) => {
        setPreferences({
            ...preferences,
            active: {
                tool: object
            },
            [object]: {
                ...preferences.[object],
                [key]: value
            }
        });
    };

    const hexToRgbA = (key, hex) => {
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {

            c = hex.substring(1).split('');
            if(c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + preferences.[key].opacity + ')';
        }
        throw new Error('Bad Hex');
    }

    return (
        <div className="app" onContextMenu={(e) =>  e.preventDefault()}>
            {/*{JSON.stringify(preferences)}*/}
            <div className="drawing-elements">
                <ul>
                    <li className={`${active('active', 'tool', 'pencil')}`}>
                        <div className="tool"
                            onClick={() => {
                                openCloseOptions('pencil');
                                updatePreferences('active', 'tool', 'pencil');
                            }}
                        >
                            <img src="icons/pencil.svg" alt=""/>
                        </div>
                        {openOptions === 'pencil' &&
                            <ul>
                                <li>{createWidthOptions('pencil')}</li>
                                {createColorOptions('pencil')}
                            </ul>
                        }
                    </li>
                    <li className={`${active('active', 'tool', 'highlight')}`}>
                        <div className="tool"
                            onClick={() => {
                                openCloseOptions('highlight');
                                updatePreferences('active', 'tool', 'highlight');
                            }}
                        >
                            <img src="icons/highlight.svg" alt=""/>
                        </div>
                        {openOptions === 'highlight' &&
                            <ul>
                                {createColorOptions('highlight')}
                            </ul>
                        }
                    </li>
                    <li className={`${active('active', 'tool', 'eraser')}`}>
                        <div className="tool"
                            onClick={() => {
                                openCloseOptions('eraser');
                                updatePreferences('active', 'tool', 'eraser');
                            }}
                        >
                            <img src="icons/eraser.svg" alt=""/>
                        </div>
                        {openOptions === 'eraser' &&
                            <ul>
                                <li>{createWidthOptions('eraser')}</li>
                            </ul>
                        }
                    </li>
                </ul>
            </div>
            <CustomCanvas 
                preferences={preferences}
            />
        </div>
  );
}

export default App;









