import React, { useRef, useState, useEffect, useCallback } from 'react';

const CustomCanvas = (props) => {
	
	const canvasRef = useRef(null);
	const canvasHolder = useRef(null);
	const [clicked, setClicked] = useState(false);
	const [posX, setPosX] = useState(0);
	const [posY, setPosY] = useState(0);

	const [activeSet, setActiveState] = useState([]);
	const [resetHighlight, setResetHighlight] = useState(false);

	useEffect(() => {
		let canvas = canvasRef.current;
		let context = canvas.getContext('2d');
		context.canvas.height = canvasHolder.current.clientHeight;
		context.canvas.width = canvasHolder.current.clientWidth;
	}, []);

	const imageLoad = useCallback((reset) => {

		let canvas = canvasRef.current;
		let ctx = canvas.getContext('2d');
		var imageObj = new Image();
		if(reset) {
			imageObj.src = activeSet;
		}
		else {
			imageObj.src = canvas.toDataURL();
			setActiveState(canvas.toDataURL());	
		}		
		imageObj.onload = () => {
        	ctx.clearRect(0, 0, canvasHolder.current.clientWidth, canvasHolder.current.clientHeight);
        	ctx.drawImage(imageObj, 0, 0, canvasHolder.current.clientWidth, canvasHolder.current.clientHeight, 0, 0, canvasHolder.current.clientWidth, canvasHolder.current.clientHeight);
		};
	}, [activeSet]);

	const startDraw = (e) => {

		if(resetHighlight) {
			imageLoad(true);
		}
		setPosX(e.nativeEvent.offsetX);
	    setPosY(e.nativeEvent.offsetY);
	    setClicked(true);
	}

	useEffect(() => {

		if(props.preferences.active.tool === 'highlight') {
			imageLoad(false);
		}
	}, [props.preferences.active.tool, imageLoad]);

	const draw = (x, y) => {
		
		if(clicked) {
			let canvas = canvasRef.current;
			let context = canvas.getContext('2d');
			context.lineWidth = props.preferences.[props.preferences.active.tool].width;
			context.lineJoin = context.lineCap = 'round';
			context.strokeStyle = props.preferences.[props.preferences.active.tool].color;
			context.beginPath();
			context.moveTo(posX, posY);
			context.lineTo(x, y);
			context.stroke();
			context.closePath();
	        setPosX(x);
	        setPosY(y);
    	}
	}

	const checkToReset = () => {

		setClicked(false);
		if(props.preferences.active.tool === 'highlight') {
			setResetHighlight(true);
		}
		else {
			setResetHighlight(false);
		}
	}

	return(
		<div className="canvas-holder" ref={canvasHolder}>
			<canvas 
				ref={canvasRef}
				onMouseUp={() => checkToReset()}
				onMouseDown={(e) => startDraw(e)}
				onMouseMove={(e) => draw(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
				onMouseOut={() => {
					setClicked(false);					
				}}
				height="400"
				width="600"
			/>
		</div>
	);
};

export default CustomCanvas;















